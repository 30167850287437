(function ($) {
    'use strict';

    function addSubscriber() {
        var form = $(this).closest('form');
        if (form.length <= 0) {
            return;
        }

        var subscriberClass = 'person-form';
        var nameRegex = /(subscribers)\[[0-9]+\]\./gi;
        var idRegex = /(subscribers)_[0-9]+__/gi;
        var subscribers = form.find('.' + subscriberClass);
        var nrSubscribers = subscribers.length;
        var lastSubscriber = subscribers.last();

        var template = lastSubscriber.html()
            .replace(nameRegex, "$1[" + nrSubscribers + "].")
            .replace(idRegex, "$1_" + nrSubscribers + "__");
        //$(template).find("input,select,textarea").each(function (inputIndex, input) {
        //    $(input).removeClass("valid");
        //});
        lastSubscriber.after('<div class="' + subscriberClass + '">' + template + '</div>');
        //remove validations icons of the new subscriber
        lastSubscriber = form.find('.' + subscriberClass).last();
        $(lastSubscriber).find("input,select,textarea").each(function (inputIndex, input) {
            $(input).removeClass('valid input-validation-error');
        });
        var firstSubscriber = subscribers.first();
        var streetname = $(firstSubscriber).find('.streetname').val();
        var streetnumber = $(firstSubscriber).find('.streetnumber').val();
        var addressaddition = $(firstSubscriber).find('.addressaddition').val();
        var zipcodenumbers = $(firstSubscriber).find('.zipcodenumbers').val();
        var zipcodeletters = $(firstSubscriber).find('.zipcodeletters').val();
        var residence = $(firstSubscriber).find('.residence').val();
        $(lastSubscriber).find(".streetname").val(streetname);
        $(lastSubscriber).find(".streetnumber").val(streetnumber);
        $(lastSubscriber).find(".addressaddition").val(addressaddition);
        $(lastSubscriber).find(".zipcodenumbers").val(zipcodenumbers);
        $(lastSubscriber).find(".zipcodeletters").val(zipcodeletters);
        $(lastSubscriber).find(".residence").val(residence);
    }

    function deleteSubscriber() {
        var nrPersons = $('.subscribe-form').find('.person-form').length;
        if (nrPersons === 1) {
            return;
        }
        var subscriberForm = $(this).closest('.person-form');
        
        subscriberForm.remove();
        //generate new indexes for current forms

        var nameRegex = /(subscribers)\[[0-9]+\]\./gi;
        var idRegex = /(subscribers)_[0-9]+__/gi;
        var subscribers = $('.subscribe-form').find('.person-form');


        $('.subscribe-form .person-form').each(function(index, form) {
            $(form).find("input,select,textarea").each(function(inputIndex, input) {
                if (typeof $(input).attr("name") !== typeof undefined && $(input).attr("name") !== false){
                    $(input).attr("name", $(input).attr("name").replace(nameRegex, "$1[" + index + "]."));
                    $(input).attr("id", $(input).attr("id").replace(idRegex, "$1[" + index + "]."));
                }
            });
        });

        //$('.subscribe-form input').each(function (index, input) {
        //    if (typeof $(input).attr("name") !== typeof undefined && $(input).attr("name") !== false){
        //        $(input).attr("name", $(input).attr("name").replace(nameRegex, "$1[" + index + "]."));
        //    }
        //});
        //$.each(subscribers, function (index) {
        //    var template = $(this).html().replace(nameRegex, "$1[" + index + "].")
        //    .replace(idRegex, "$1_" + index + "__");
        //    //$('.subscribe-form').find('.person-form').eq(index).html(template);
        //});

    }

    $(document).ready(function () {
        $('#addperson').click(addSubscriber);
        $('.subscribe-form').on('click', '.remove-person', deleteSubscriber);
    });
})(jQuery);